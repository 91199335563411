<template>
  <div class="content-add-edit-panel" :class="{ closing: isClosing }">
    <v-row
      class="content-add-edit-panel-title paragraph-s pa-0 ma-0 w-100 d-flex align-center justify-space-between flex-nowrap"
    >
      <v-col v-if="titleTranslationPath" class="py-4 px-0" md="10">{{ $t(titleTranslationPath) }}</v-col>
      <v-col class="py-0 flex-grow-0 ml-1" md="2">
        <v-btn class="mr-n5 pa-0" color="white" @click="close">
          <PtrIcon icon="close" color="#9095A2" width="16" editable-color />
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="my-0 mx-0 px-0">
      <component
        :is="formComponent"
        ref="addEditComponent"
        :feature-id="featureId"
        @valid="(isValid) => (valid = isValid)"
      >
        <template v-if="isEdit" #danger-zone>
          <v-expansion-panel
            active-class="expanded"
            class="danger"
            :disabled="isMapBorderEnabled"
            style="background-color: white !important"
          >
            <v-expansion-panel-header>
              <PtrIcon class="expansion-panel-header-icon" icon="alert-triangle" />
              <div class="expansion-panel-header-text">{{ $t(`${translationPath}danger-zone`) }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn color="error" class="ml-n2" small plain @click="toggleDeleteContentModal(true)">
                {{ $t(`${translationPath}delete`) }}
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </component>
    </v-row>

    <DeleteContentModal
      v-if="shouldDisplayDeleteContentModal"
      @confirm="deleteContent"
      @goBack="toggleDeleteContentModal(false)"
    />

    <v-row class="form-buttons-row" :class="{ closing: isClosing }">
      <v-col class="pr-0 d-flex align-center justify-space-between">
        <v-btn id="cancel" class="form-buttons cancel-button" outlined @click="close">{{ $t("cancel") }}</v-btn>

        <v-btn
          id="save"
          :disabled="!valid || !isFormDirty || isMapBorderEnabled"
          class="form-buttons"
          color="primary"
          depressed
          @click="save"
        >
          {{ isEdit ? $t("save") : $t("add") }}
        </v-btn>
      </v-col>
    </v-row>
    <NewDiscardChangesModal
      v-if="shouldDisplayDiscardChangesModal"
      :cancel-action="hideDiscardChangesModal"
      :confirm-action="onDiscardConfirmed"
    ></NewDiscardChangesModal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import GeofenceAddEditView from "@/components/mapDesigner/GeofenceAddEditView.vue";
import PoiAddEditView from "@/components/mapDesigner/PoiAddEditView.vue";
import BeaconAddEditView from "@/components/mapDesigner/BeaconAddEditView.vue";
import PathAddEditView from "@/components/mapDesigner/PathAddEditView.vue";
import TransitionAddEditView from "@/components/mapDesigner/TransitionAddEditView.vue";
import LevelAddEditView from "@/components/LevelAddEditView.vue";
import SiteAddEditView from "@/components/SiteAddEditView.vue";
import GlobalGeofenceAddEditView from "@/components/GlobalGeofenceAddEditView.vue";
import BuildingAddEditView from "@/components/BuildingAddEditView.vue";
import OutdoorPathAddEditView from "@/components/OutdoorPathAddEditView.vue";
import OutdoorTransitionAddEditView from "@/components/OutdoorTransitionAddEditView.vue";
import DeleteContentModal from "@/components/shared/DeleteContentModal.vue";
import NewDiscardChangesModal from "@/components/shared/NewDiscardChangesModal";
import PtrIcon from "@/components/shared/PtrIcon.vue";

const LEVEL_CONTENTS = ["pois", "mapObjects", "geofences", "iot-devices", "wayfinding-network"];

export default {
  name: "ContentAddEditPanel",
  components: {
    PtrIcon,
    DeleteContentModal,
    NewDiscardChangesModal,
    LevelAddEditView,
    SiteAddEditView,
    GlobalGeofenceAddEditView,
    BuildingAddEditView,
    OutdoorPathAddEditView
  },
  props: {
    isClosing: Boolean
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    formComponent: undefined,
    contentType: "",
    featureId: "",
    valid: false,
    titleTranslationPath: undefined,
    shouldDisplayDeleteContentModal: false,
    shouldDisplayDiscardChangesModal: false
  }),
  computed: {
    ...mapState("CONTENT", ["isFormDirty"]),
    ...mapState("MAP", ["isMapBorderEnabled"]),
    isEdit() {
      if (this.$route.name.includes("Edit")) {
        return true;
      } else if (this.$route.name.includes("Add")) {
        return false;
      } else if (
        this.featureId !== "add" &&
        this.featureId !== undefined &&
        this.featureId !== "add-transition" &&
        this.featureId !== "add-outdoor-path" &&
        this.featureId !== "add-outdoor-transition"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isLevelContent() {
      // checks the content type if the object to add/edit is something that is contained inside a level
      // since in that case (as opposed to buildings etc), the routing logic changes
      return LEVEL_CONTENTS.indexOf(this.contentType) !== -1;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.contentType = this.$route.params.contentType;
        this.featureId = this.getEditedObjectId();
        if (this.isLevelContent && this.featureId === undefined) {
          return;
        }
        if (this.isClosing) {
          return;
        }
        this.setFormComponentAndTitle();
      }
    },
    isFormDirty() {
      if (this.isFormDirty) {
        window.addEventListener("beforeunload", this.beforeWindowUnload);
      } else {
        window.removeEventListener("beforeunload", this.beforeWindowUnload);
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
    window.removeEventListener("popstate", this.browserGoBackClicked);
  },
  created() {
    window.addEventListener("popstate", this.browserGoBackClicked);
  },
  methods: {
    setFormComponentAndTitle() {
      switch (this.contentType) {
        case "pois":
          this.setFormComponentAndTitleForPois();
          break;
        case "mapObjects":
          this.setFormComponentAndTitleForMapObjects();
          break;
        case "geofences":
          this.setFormComponentAndTitleForGeofences();
          break;
        case "iot-devices":
          this.setFormComponentAndTitleForBeacons();
          break;
        case "wayfinding-network":
          this.setFormComponentAndTitleForWayfinding();
          break;
        case "levels":
          this.setFormComponentAndTitleForLevels();
          break;
        case "sites":
          this.setFormComponentAndTitleForSites();
          break;
        case "global-geofences":
          this.setFormComponentAndTitleForGlobalGeofences();
          break;
        case "buildings":
          this.setFormComponentAndTitleForBuildings();
          break;
        case "outdoor-wayfinding-network":
          this.setFormComponentAndTitleForOutdoorWayfinding();
      }
    },
    setFormComponentAndTitleForPois() {
      this.formComponent = PoiAddEditView;
      this.titleTranslationPath = this.isEdit ? `${this.translationPath}editing-poi` : `${this.translationPath}add-poi`;
    },
    setFormComponentAndTitleForMapObjects() {
      this.formComponent = PoiAddEditView;
      this.titleTranslationPath = this.isEdit
        ? `${this.translationPath}editing-map-object`
        : `${this.translationPath}add-map-object`;
    },
    setFormComponentAndTitleForGeofences() {
      this.formComponent = GeofenceAddEditView;
      this.titleTranslationPath = this.isEdit
        ? `${this.translationPath}editing-geofence`
        : `${this.translationPath}add-geofence`;
    },
    setFormComponentAndTitleForBeacons() {
      this.formComponent = BeaconAddEditView;
      this.titleTranslationPath = this.isEdit
        ? `${this.translationPath}editing-beacon`
        : `${this.translationPath}add-beacon`;
    },
    setFormComponentAndTitleForWayfinding() {
      if (this.featureId === "add-path") {
        this.formComponent = PathAddEditView;
        this.titleTranslationPath = `${this.translationPath}add-path`;
      } else if (this.featureId === "edit-path") {
        this.formComponent = PathAddEditView;
        this.titleTranslationPath = `${this.translationPath}edit-path`;
      } else if (this.featureId === "add-transition") {
        this.formComponent = TransitionAddEditView;
        this.titleTranslationPath = `${this.translationPath}add-transition`;
      } else {
        this.formComponent = TransitionAddEditView;
        this.titleTranslationPath = `${this.translationPath}editing-transition`;
      }
    },
    setFormComponentAndTitleForOutdoorWayfinding() {
      if (this.featureId === "add-outdoor-path") {
        this.formComponent = OutdoorPathAddEditView;
        this.titleTranslationPath = `${this.translationPath}add-outdoor-path`;
      } else if (this.featureId === "edit-outdoor-path") {
        this.formComponent = OutdoorPathAddEditView;
        this.titleTranslationPath = `${this.translationPath}editing-outdoor-path`;
      } else if (this.featureId === "add-outdoor-transition") {
        this.formComponent = OutdoorTransitionAddEditView;
        this.titleTranslationPath = `${this.translationPath}add-transition`;
      } else if (this.featureId === "edit-outdoor-transition") {
        this.formComponent = OutdoorTransitionAddEditView;
        this.titleTranslationPath = `${this.translationPath}editing-transition`;
      }
    },
    setFormComponentAndTitleForLevels() {
      this.formComponent = LevelAddEditView;
      this.titleTranslationPath = this.isEdit
        ? `${this.translationPath}editing-level`
        : `${this.translationPath}add-level`;
    },
    setFormComponentAndTitleForSites() {
      this.formComponent = SiteAddEditView;
      this.titleTranslationPath = this.isEdit
        ? `${this.translationPath}editing-site`
        : `${this.translationPath}add-site`;
    },
    setFormComponentAndTitleForGlobalGeofences() {
      this.formComponent = GlobalGeofenceAddEditView;
      this.titleTranslationPath = this.isEdit
        ? `${this.translationPath}editing-global-geofence`
        : `${this.translationPath}add-global-geofence`;
    },
    setFormComponentAndTitleForBuildings() {
      this.formComponent = BuildingAddEditView;
      this.titleTranslationPath = this.isEdit
        ? `${this.translationPath}editing-building`
        : `${this.translationPath}add-building`;
    },
    close() {
      if (this.isClosing) {
        return;
      }
      if (this.isFormDirty) {
        this.shouldDisplayDiscardChangesModal = true;
      } else {
        this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
        this.$emit("close");
        this.goBack();
      }
    },
    async save() {
      const isSavedSuccessfully = await this.$refs.addEditComponent.save();
      if (isSavedSuccessfully) {
        this.$emit("close");
        this.goBack(true);
      }
    },
    async deleteContent() {
      const isDeletedSuccessfully = await this.$refs.addEditComponent.deleteContent();
      this.toggleDeleteContentModal(false);
      if (isDeletedSuccessfully) {
        this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
        this.$emit("close");
        this.goBack();
      }
    },
    browserGoBackClicked() {
      if (this.isFormDirty) {
        this.shouldDisplayDiscardChangesModal = true;
      } else {
        this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
        if (
          (this.isLevelContent && this.featureId === undefined) ||
          (!this.isLevelContent && !this.$route.name.includes("Edit") && !this.$route.name.includes("Add"))
        ) {
          this.$emit("close");
        }
      }
    },
    toggleDeleteContentModal(value) {
      this.shouldDisplayDeleteContentModal = value;
    },
    onDiscardConfirmed() {
      this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
      this.hideDiscardChangesModal();
      this.$emit("close");
      this.goBack();
    },
    goBack(goBackWithLevelData = false) {
      if (this.isLevelContent) {
        const currentPath = this.$router.history.current.path;
        this.$router.push(currentPath.substring(0, currentPath.lastIndexOf("/"))).catch((e) => console.log(e.message));
        return;
      }
      this.$refs.addEditComponent.goBack(goBackWithLevelData);
    },
    beforeWindowUnload(e) {
      if (this.isFormDirty) {
        e.returnValue = this.$t("contents.discardChangesModal.unsaved-changes");
      }
    },
    hideDiscardChangesModal() {
      this.shouldDisplayDiscardChangesModal = false;
    },
    getEditedObjectId() {
      let { featureId, levelId, siteId, globalGeofenceId, buildingId } = this.$route.params;
      switch (this.contentType) {
        case "pois":
          return featureId;
        case "mapObjects":
          return featureId;
        case "geofences":
          return featureId;
        case "iot-devices":
          return featureId;
        case "wayfinding-network":
          return featureId;
        case "levels":
          return levelId;
        case "sites":
          return siteId;
        case "global-geofences":
          return globalGeofenceId;
        case "buildings":
          return buildingId;
        case "outdoor-wayfinding-network":
          return featureId;
        default:
          console.warn("Invalid content type", this.contentType);
          return featureId;
      }
    },
    onDropFile(file) {
      this.$refs.addEditComponent.onDropFile(file);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

$min-direct-child-width: $content-add-edit-panel-width - 2 * 16;

.content-add-edit-panel {
  overflow: hidden;
  height: 100%;
  max-height: 100vh;
  animation: panelAnimation 0.4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  box-sizing: border-box;
  flex-shrink: 0;

  > div {
    min-width: $min-direct-child-width;
    max-height: calc(100% - 120px);
    overflow: hidden auto;

    &:not(.form-buttons-row) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .content-add-edit-panel-title {
    font-weight: #{$font-weight-semi-bold};
    color: var(--v-neutral-darken1);
    width: 100%;
  }

  .form-buttons-row {
    position: fixed;
    bottom: 16px;
    z-index: 9;
    animation: fadeIn 1s ease-in;

    .form-buttons {
      width: 140px;
      height: 48px;

      &.cancel-button {
        margin-right: 8px;
      }
    }

    &.closing {
      opacity: 0;
      transition: opacity 0.1s linear;
      background: transparent;
    }
  }

  &.closing {
    animation: closeAnimation 0.4s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  ::v-deep textarea,
  ::v-deep .theme--light.v-input input {
    font-size: #{$font-size-2};
    color: var(--v-primary-base);
  }

  .error--text:hover {
    opacity: 0.2 !important;
  }
}

@keyframes panelAnimation {
  from {
    width: 0;
    padding-left: 0;
    padding-right: 0;
  }
  to {
    width: #{$content-add-edit-panel-width};
    padding-left: 16px;
    padding-right: 16px;
  }
}

@keyframes closeAnimation {
  0% {
    width: #{$content-add-edit-panel-width};
    padding-left: 16px;
    padding-right: 16px;
  }
  20% {
    width: 224px;
    padding-right: 0;
    padding-left: 16px;
  }
  80% {
    width: 56px;
    padding-right: 0;
    padding-left: 8px;
  }

  100% {
    width: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    background: transparent;
  }
  100% {
    opacity: 1;
    background: var(--v-white-base);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

::v-deep {
  .poi-property-input-container {
    border-radius: 8px;
    border: 2px solid var(--v-scrollbarGray-base);
    background: var(--v-white-base);
  }

  .property-header {
    color: var(--v-neutral-base);
    font-size: #{$font-size-0};
    line-height: #{$line-height-15};
  }

  .content-add-edit-subtitle {
    color: var(--v-neutral-darken4);
    font-size: #{$font-size-6};
    line-height: #{$line-height-10}; /* 150% */
  }
}
</style>
