<template>
  <div>
    <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
      <v-expansion-panel active-class="expanded" class="mt-0">
        <v-expansion-panel-header>
          <PtrIcon class="expansion-panel-header-icon" icon="information" />
          <div class="expansion-panel-header-text">{{ $t(`${translationPath}basic-information`) }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="mdpoi" v-model="valid" class="mt-2 mb-3">
            <v-row class="d-flex flex-column" :class="{ 'editing-geometry': isMapBorderEnabled }">
              <v-col class="pt-0">
                <GeometrySection
                  ref="geometrySection"
                  feature-id="outdoor-path"
                  :should-show-polygon-icon="false"
                  :should-show-point-icon="false"
                  is-path
                  :is-edit="$route.params?.featureId === 'edit-path'"
                  @pathClicked="drawManuallyClicked"
                  @editGeometry="editGeometry"
                  @clear="clearClicked"
                >
                  <template #auto-generate>
                    <div class="mb-4 geometry-sub-title override">
                      {{ $t(`${translationPath}path-geometry-description`) }}
                    </div>
                    <div class="paragraph-s mb-1 auto-generate-title">
                      {{ $t(`${translationPath}auto-generate.title`) }}
                    </div>
                    <div class="mb-2 geometry-sub-title override">
                      {{ $t(`${translationPath}auto-generate.description`) }}
                    </div>
                    <v-btn
                      color="primary darken-btn"
                      class="mb-4"
                      block
                      depressed
                      style="min-height: 40px !important"
                      height="40"
                      :disabled="isDrawing && isMapBorderEnabled"
                      @click="onAutoGenerateClicked"
                      >{{ $t(`${translationPath}auto-generate.action`) }}
                    </v-btn>
                    <div class="paragraph-s mb-1 auto-generate-title">
                      {{ $t(`${translationPath}manual.title`) }}
                    </div>
                    <div class="geometry-sub-title override">
                      {{ $t(`${translationPath}manual.description`) }}
                    </div>
                  </template>
                </GeometrySection>
                <div class="pb-4 geometry-sub-title">{{ $t(`${translationPath}wayfinding-network-geometry-sub`) }}</div>
              </v-col>
            </v-row>
          </v-form>
          <div class="form-footer paragraph-xs mt-5">
            {{ $t(`${translationPath}required`) }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="show" internal-activator persistent width="400" eager>
      <v-card class="pt-8 pb-8">
        <v-card-title color="" class="text-h5 justify-center font-weight-bold">
          {{ $t(`${translationPath}auto-path-are-you-sure`) }}
        </v-card-title>
        <v-card-text class="text-center">{{ $t(`${translationPath}aute-path-unsaved-changes`) }}</v-card-text>
        <v-card-actions class="justify-center d-flex flex-column">
          <v-btn id="discard-modal-leave-btn" color="primary base" @click="confirmAreYouSure">
            {{ $t(`${translationPath}auto-path-confirm`) }}
          </v-btn>
          <v-btn id="discard-modal-stay-btn" color="neutral lighten1" text @click="cancelAreYouSure">
            {{ $t(`${translationPath}auto-path-cancel`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import GeometrySection from "./GeometrySection.vue";
import GraphMode from "@/helpers/drawModes/GraphMode";
import ContentService from "@/services/ContentService";
import MapHelpers from "@/helpers/MapHelpers";
import ToastHelpers from "@/helpers/ToastHelpers";

export default {
  components: { PtrIcon, GeometrySection },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    valid: false,
    showGeometryOptions: true,
    defaultOpenPanelIndex: 0,
    pathUpdated: false,
    show: false,
    isDrawing: false
  }),
  computed: {
    ...mapState("MAP", [
      "isMapReady",
      "map",
      "mapMode",
      "currentClient",
      "currentSite",
      "currentBuilding",
      "currentLevel",
      "isMapBorderEnabled",
      "drawnCoordinates"
    ]),
    ...mapState("CONTENT", ["graphs", "filteredGraphs"]),
    rules() {
      return {
        required: (value) => ValidationHelpers.isRequired(value)
      };
    },
    taxonomy() {
      return this.$store.state.taxonomy;
    },
    isTherePath() {
      return this.filteredGraphs.find(
        (feature) =>
          feature.properties.typeCode === "graph-node" &&
          feature.properties.lvl === this.currentLevel &&
          feature.properties.bid === this.currentBuilding
      );
    }
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    },
    $route: {
      handler(newVal, oldVal) {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        this.cancelClicked();
        this.pathUpdated = false;
        const graphFeaturesToShow = this.graphs.filter((feature) => {
          return (
            (feature.properties.bid === this.currentBuilding && feature.properties.lvl === this.currentLevel) ||
            (feature.properties.typeCode === "building-entrance-exit" &&
              feature.properties.bid == undefined &&
              feature.properties.lvl == undefined)
          );
        });
        // to enable after panel animation
        setTimeout(() => {
          if (!oldVal?.fullPath?.includes("path")) {
            GraphMode.createNodesFromFeatures(graphFeaturesToShow);
            GraphMode.generateGraph();
            GraphMode.toggle({ shouldEnable: false });
          }
        }, 500);
      },
      immediate: true
    },
    pathUpdated() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", this.pathUpdated);
    }
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.map.on("contextmenu", this.mapRightClicked);
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    GraphMode.toggle({ shouldEnable: false });
    this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
    this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
    this.map.off("contextmenu", this.mapRightClicked);
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
  },
  methods: {
    async autoGeneratedClicked() {
      let generatedGraph = await ContentService.autoGenerate(
        this.currentClient,
        this.currentBuilding,
        this.currentLevel
      );
      if (generatedGraph?.code && generatedGraph?.message) {
        ToastHelpers.createErrorToast(generatedGraph?.message);
        return;
      }
      this.pathUpdated = true;
      this.showGeometryOptions = false;
      const graphFeaturesToShow = this.graphs.filter((feature) => {
        return (
          (feature.properties.typeCode !== "graph-node" &&
            feature?.properties?.bid == this.currentBuilding &&
            feature?.properties?.lvl == this.currentLevel) ||
          (feature.properties.typeCode === "building-entrance-exit" &&
            feature.properties.bid == undefined &&
            feature.properties.lvl == undefined)
        );
      });
      if (generatedGraph?.features) {
        graphFeaturesToShow.push(...generatedGraph.features);
      }
      GraphMode.clear();
      GraphMode.create({
        map: this.map,
        sid: this.currentSite,
        bid: this.currentBuilding,
        lvl: this.currentLevel,
        taxonomy: this.taxonomy
      });
      GraphMode.createNodesFromFeatures(graphFeaturesToShow);
      GraphMode.generateGraph();
      GraphMode.toggleMiddlePoints(false);
      this.$refs.geometrySection.isDrawingFinished = true;
      this.$refs.geometrySection.geometryAction = undefined;
    },
    drawManuallyClicked() {
      if (this.pathUpdated) {
        this.mapRightClicked();
      }
      this.pathUpdated = true;
      this.showGeometryOptions = false;
      GraphMode.toggle({ shouldEnable: true });
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
      this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.finish-drawing"));
      this.map.on("click", this.mapClicked);
    },
    mapClicked() {
      this.isDrawing = true;
      this.map.off("click", this.mapClicked);
    },
    cancelClicked() {
      this.showGeometryOptions = true;
      GraphMode.toggle({ shouldEnable: false });
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
      this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
    },
    mapRightClicked() {
      if (this.isMapBorderEnabled) {
        setTimeout(() => {
          this.cancelClicked();
        });
      }
    },
    editGeometry() {
      GraphMode.toggle({ shouldEnable: true });
      this.pathUpdated = true;
    },
    clearClicked() {
      GraphMode.toggle({ shouldEnable: false });
      GraphMode.clear();
      let graphFeaturesToShow = this.graphs.filter((feature) => {
        const isSameLevelTransition =
          feature.properties.typeCode !== "graph-node" &&
          feature?.properties?.bid == this.currentBuilding &&
          feature?.properties?.lvl == this.currentLevel;
        return isSameLevelTransition;
      });
      this.removeEdges(graphFeaturesToShow);
      graphFeaturesToShow.push(
        ...this.graphs.filter((feature) => {
          return (
            feature.properties.typeCode === "building-entrance-exit" &&
            feature.properties.bid == undefined &&
            feature.properties.lvl == undefined
          );
        })
      );
      GraphMode.createNodesFromFeatures(graphFeaturesToShow);
      GraphMode.generateGraph();
    },
    removeEdges(features) {
      return features.forEach((feature) => {
        feature.properties.neighbors = [];
      });
    },
    onAutoGenerateClicked() {
      if (this.isTherePath || GraphMode.graphData.features.length !== 0) {
        this.show = true;
      } else {
        this.confirmAreYouSure();
      }
    },
    confirmAreYouSure() {
      this.autoGeneratedClicked();
      this.show = false;
    },
    cancelAreYouSure() {
      this.show = false;
    },
    async save() {
      const newGraphJson = GraphMode.exportAsGeoJson();
      const otherGraphNodes = this.graphs.filter((feature) => {
        return !(feature.properties.lvl === this.currentLevel && feature.properties.bid === this.currentBuilding);
      });
      newGraphJson.features.push(...otherGraphNodes);
      let response = await ContentService.postGraphBySite(this.currentSite, newGraphJson);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.commit("CONTENT/LOCAL_GRAPHS", newGraphJson.features);
        return true;
      } else {
        return false;
      }
    },

    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.editing-geometry {
  background: var(--v-primary-lighten4);
}

.content-add-edit-expansion-panels {
  width: #{$content-add-edit-panel-max-child-width};
}

.auto-generate-title {
  color: var(--v-neutral-darken3);
}

::v-deep .geometry-sub-title:not(.override) {
  display: none;
}
</style>
