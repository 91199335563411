<template>
  <div>
    <div :id="mapBoxOptions.container"></div>
  </div>
</template>

<script>
import maplibregl from "maplibre-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode } from "mapbox-gl-draw-circle";
import CutPolygonMode from "@/helpers/drawModes/CutMode";
import DisableMode from "@/helpers/drawModes/DisableMode";
import mapboxGlDrawPassingMode from "mapbox-gl-draw-passing-mode";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import GraphMode from "@/helpers/drawModes/GraphMode";
import PointMode from "@/helpers/drawModes/PointMode";

import StyleJsonService from "@/services/StyleJsonService";

import { mapState } from "vuex";

import MapEventConstants from "@/constants/MapEventConstants";
import MapModeConstants from "@/constants/mapModeConstants";
import MapConstants from "@/constants/mapConstants";
import { setIpadHeight } from "@/helpers/StyleHelpers";

const MAP_ID = "content-map";

export default {
  name: "ContentMap",
  props: {},

  data: () => ({
    map: undefined,
    mapDraw: undefined,
    mapBoxOptions: {
      container: MAP_ID,
      style: "https://pointr-maps.azureedge.net/style/8.7.9/light_PCD.json",
      center: [-115.165653, 36.129151],
      zoom: 13
    },
    drawOptions: {
      displayControlsDefault: false,
      controls: {
        trash: true
      },
      modes: {
        ...MapboxDraw.modes,
        [MapModeConstants.DISABLE]: DisableMode,
        [MapModeConstants.DRAW_CIRCLE]: CircleMode,
        [MapModeConstants.DRAG_CIRCLE]: DragCircleMode,
        [MapModeConstants.DIRECT_SELECT]: DirectMode,
        [MapModeConstants.SIMPLE_SELECT]: SimpleSelectMode,
        [MapModeConstants.CUT_POLYGON]: CutPolygonMode,
        [MapModeConstants.DRAW_RECTANGLE]: DrawRectangle,
        [MapModeConstants.PASSING_MODE_POLYGON]: mapboxGlDrawPassingMode(MapboxDraw.modes.draw_polygon)
      }
    },
    hoveredStateId: undefined
  }),
  computed: {
    ...mapState("MAP", [
      "drawnCoordinates",
      "mapMode",
      "zoom",
      "minZoom",
      "maxZoom",
      "isAddEditPanelActive",
      "shouldDisplayOriginalFloorPlan"
    ]),
    ...mapState("CONTENT", ["pois"]),
    taxonomy() {
      return this.$store.state.taxonomy;
    },
    backgroundLoadingRequestCount() {
      return this.$store.state.backgroundLoadingRequestCount;
    }
  },
  watch: {
    mapMode(newVal) {
      let feature;
      switch (newVal) {
        case MapModeConstants.DRAW_RECTANGLE:
        case MapModeConstants.DRAW_POLYGON:
          if (this.drawnCoordinates) {
            return;
          }
          this.mapDraw.changeMode(newVal);
          break;
        case MapModeConstants.DRAW_POINT:
          this.mapDraw.changeMode(MapModeConstants.SIMPLE_SELECT);
          PointMode.toggle();
          PointMode.on("point.create", this.pointChanged);
          PointMode.on("point.update", this.pointChanged);
          PointMode.on("point.delete", this.pointChanged);
          break;
        case MapModeConstants.DRAG_CIRCLE:
          if (this.drawnCoordinates) {
            return;
          }
          this.mapDraw.changeMode(newVal, { initialRadiusInKm: 0.01 });
          break;
        case MapModeConstants.CUT_POLYGON:
          feature = this.mapDraw.getAll()?.features?.[0];
          if (!feature?.id) {
            console.warn("Cannot set cut mode - No polygon is selected");
            return;
          }
          this.mapDraw.changeMode(MapModeConstants.DIRECT_SELECT, { featureId: feature.id });
          this.mapDraw.changeMode(newVal);
          break;
        case MapModeConstants.DISABLE:
          this.mapDraw.changeMode(newVal);
          break;
        case MapModeConstants.DRAW_GRAPH_MODE:
          GraphMode.toggle();
          break;
        default:
          GraphMode.toggle({ shouldEnable: false });
          PointMode.toggle({ shouldEnable: false });
          PointMode.off("point.create", this.pointChanged);
          PointMode.off("point.update", this.pointChanged);
          PointMode.off("point.delete", this.pointChanged);
          this.mapDraw.changeMode(MapModeConstants.SIMPLE_SELECT);
          break;
      }
    }
  },

  async mounted() {
    this.mapBoxOptions.minZoom = this.minZoom;
    this.mapBoxOptions.maxZoom = this.maxZoom;

    const styleJson = await StyleJsonService.getStyleJson();
    // To support 8.0.0 they're still in style json
    styleJson.layers = styleJson.layers.filter(
      (layer) =>
        layer.id !== "meta_symbol_building_boundaries_ptr" &&
        layer.id !== "meta_symbol_site_boundaries_ptr" &&
        layer.id !== "meta_building_boundaries_ptr"
    );
    // Update source for the site fill_site-outline
    const fillSiteOutlineIndex = styleJson.layers.findIndex((layer) => layer.id === "fill_site-outline_ptr");
    styleJson.sources[MapConstants.SITE_BORDERS_SOURCE] = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    };
    styleJson.layers[fillSiteOutlineIndex].source = MapConstants.SITE_BORDERS_SOURCE;

    // Update source for the site fill_building-outline
    const fillBuildingOutlineIndex = styleJson.layers.findIndex((layer) => layer.id === "fill_building-outline_ptr");
    styleJson.sources[MapConstants.BUILDING_BORDERS_SOURCE] = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    };
    styleJson.layers[fillBuildingOutlineIndex].source = MapConstants.BUILDING_BORDERS_SOURCE;
    // TODO: To support arabic we need different font. Once style is updated remove this section completely
    // styleJson.glyphs = "https://pointrmapstorage.blob.core.windows.net/style/8.7.0/fonts/{fontstack}/{range}.pbf";
    // styleJson.layers.forEach((layer) => {
    //   if (layer.layout && layer.id.includes("symbol")) {
    //     layer.layout["text-font"] = ["Arial Unicode MS Regular"];
    //   }
    // });

    this.mapBoxOptions.style = styleJson;

    if (maplibregl.getRTLTextPluginStatus() === "unavailable") {
      maplibregl.setRTLTextPlugin(
        "https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.min.js",
        null,
        true // Lazy load the plugin
      );
    }

    this.map = new maplibregl.Map(this.mapBoxOptions);
    this.$store.commit("ADD_LOADING_REQUEST");
    this.map.on(MapEventConstants.LOAD, () => {
      this.mapLoaded();
    });
    this.map.on("idle", () => {
      const isInViewPort = this.map.queryRenderedFeatures().some((feature) => {
        return feature?.layer?.id?.endsWith("ptr");
      });
      this.$store.commit("MAP/TOGGLE_UI_ICON_DISABILITY_STATE", { iconName: "target", isDisabled: isInViewPort });
      // We're adding background loading while changing map objects.
      // This allow us to disable the UI while loading new map objects. And when it is full visible we enable again
      setTimeout(() => {
        this.$store.commit("REMOVE_BACKGROUND_LOADING_REQUEST");
      }, 250);
    });

    this.map.on("mousemove", (e) => this.mouseMoveEventHandler(e));
    this.map.on("mouseout", this.mouseOutEventHandler);
    this.map.on("click", (e) => this.clickEventHandler(e));

    GraphMode.init();
    GraphMode.on("graph.nodeSelected", this.graphNodeSelected);

    PointMode.init();
  },

  async created() {
    window.addEventListener("resize", setIpadHeight);
    setIpadHeight();
  },

  beforeDestroy() {
    this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
    this.map.off(MapEventConstants.DRAW_CREATE, this.setDrawnPolygon);
    this.map.off(MapEventConstants.DRAW_UPDATE, this.setDrawnPolygon);
    this.map.off(MapEventConstants.DRAW_DELETE, this.setDrawnPolygon);
    GraphMode.clearEvents();
    this.$store.commit("MAP/MAP_MODE", MapModeConstants.SIMPLE_SELECT);
    this.map.remove();
    this.map = undefined;
    this.mapDraw = undefined;
    this.$store.commit("MAP/MAP_READY", false);
    window.removeEventListener("resize", setIpadHeight);
  },

  methods: {
    mapLoaded() {
      this.$store.commit("MAP/MAP", this.map);
      this.map.on(MapEventConstants.ZOOM_END, this.setZoom);
      this.createAndAddDraw();
      let interval = setInterval(() => {
        if (this.map?.isStyleLoaded()) {
          clearInterval(interval);
          this.$store.commit("MAP/MAP_READY", true);
          this.$store.commit("REMOVE_LOADING_REQUEST");
          PointMode.create({ map: this.map });
          GraphMode.create({ map: this.map });
        }
      });

      // line layer for cad to geojsons
      if (!this.map.getLayer("ptr-line-layer")) {
        this.map.addLayer({
          id: "ptr-line-layer",
          type: "line",
          source: "source_ptr",
          layout: { "line-join": "round", "line-cap": "round", visibility: "visible" },
          paint: {
            "line-width": {
              stops: [
                [0, 1],
                [12, 2]
              ]
            },
            "line-color": "rgb(0,161,189)"
          },
          filter: ["==", ["geometry-type"], "LineString"]
        });
      }
    },
    createAndAddDraw() {
      this.mapDraw = new MapboxDraw(this.drawOptions);
      this.$store.commit("MAP/MAP_DRAW", this.mapDraw);
      this.map.addControl(this.mapDraw);
      this.addDrawnPolygonFeature();
    },
    addDrawnPolygonFeature() {
      this.map.on(MapEventConstants.DRAW_MODE_CHANGE, (e) => {
        this.setDrawnPolygon();
        // cut_polygon expects no intervention
        if (e.mode !== MapModeConstants.PASSING_MODE_POLYGON) {
          this.$store.commit("MAP/MAP_MODE", e.mode);
        }
      });
      this.map.on(MapEventConstants.DRAW_CREATE, this.setDrawnPolygon);
      this.map.on(MapEventConstants.DRAW_UPDATE, this.setDrawnPolygon);
      this.map.on(MapEventConstants.DRAW_DELETE, this.setDrawnPolygon);
    },
    setDrawnPolygon() {
      let polygon = this.mapDraw.getAll().features[0];
      const isMultiPolygon = polygon.geometry.type === "MultiPolygon";
      if (isMultiPolygon) {
        polygon.geometry.coordinates = polygon.geometry.coordinates.map((outerArr) => {
          return outerArr.map((innerArr) => {
            return innerArr.map((coord) => [Number(coord[0].toFixed(8)), Number(coord[1].toFixed(8))]);
          });
        });
      } else {
        polygon.geometry.coordinates = polygon.geometry.coordinates.map((outerArr) => {
          return outerArr.map((coord) => {
            return [Number(coord[0].toFixed(8)), Number(coord[1].toFixed(8))];
          });
        });
      }

      this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: polygon });
    },
    setZoom() {
      this.$store.commit("MAP/ZOOM", this.map.getZoom());
    },
    pointChanged() {
      const pointCoordinate = PointMode.exportCoordinates()[0];
      const pointFixedCoordinates = [Number(pointCoordinate[0].toFixed(8)), Number(pointCoordinate[1].toFixed(8))];
      this.$store.commit("MAP/DRAWN_COORDINATES", JSON.stringify(pointFixedCoordinates));
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    graphNodeSelected(node) {
      this.$store.commit("CONTENT/SELECTED_CONTENT", node);
    },
    enableHighlight(source = MapConstants.SOURCE) {
      if (this.hoveredStateId !== undefined) {
        this.map.setFeatureState({ source: source, id: this.hoveredStateId }, { hover: true });
      }
    },
    disableHighlight() {
      if (this.hoveredStateId !== undefined) {
        this.map.setFeatureState({ source: MapConstants.SOURCE, id: this.hoveredStateId }, { hover: false });
        this.map.setFeatureState(
          { source: MapConstants.BUILDING_BORDERS_SOURCE, id: this.hoveredStateId },
          { hover: false }
        );
      }
    },
    getFocusedFeature(features) {
      for (const element of features) {
        if (
          (!this.shouldDisplayOriginalFloorPlan &&
            (element.layer.id.startsWith("fill") || element.layer.id.startsWith("symbol"))) ||
          (this.shouldDisplayOriginalFloorPlan &&
            (element.layer.id.startsWith("fill") || element.layer.id.startsWith("symbol")) &&
            !element.layer.id.includes("undefined"))
        ) {
          return element;
        }
      }
      return undefined;
    },
    mouseMoveEventHandler(e) {
      if (
        this.$route.name.includes("Edit") ||
        this.$route.name.includes("Add") ||
        this.$route.name.includes("Level") ||
        this.$route.name.includes("Picker") ||
        this.$route.name.includes("WayfindingNetwork") ||
        this.$route.name.includes("GeofenceList")
      ) {
        return;
      }
      const boundingBox = [
        [e.point.x - 1, e.point.y - 1],
        [e.point.x + 1, e.point.y + 1]
      ];
      let selectedFeatures = this.map.queryRenderedFeatures(boundingBox).filter((feature) => {
        return feature.layer.id.endsWith("ptr");
      });
      if (selectedFeatures.length > 0) {
        if (this.hoveredStateId !== undefined) {
          this.disableHighlight();
          this.hoveredStateId = undefined;
        }
        const hoveredFeature = this.getFocusedFeature(selectedFeatures);
        const shouldHighlightPoi =
          this.$route.name.includes("PoiList") && this.isPoi(hoveredFeature) && !this.isAddEditPanelActive;
        const shouldHighlightMapObject = this.$route.name.includes("MapObjectList") && !this.isAddEditPanelActive;
        const shouldHighlightBuilding = this.$route.name.includes("Buildings");

        if (shouldHighlightPoi || shouldHighlightMapObject || shouldHighlightBuilding) {
          this.hoveredStateId = hoveredFeature?.id;
          this.enableHighlight(hoveredFeature?.source);
        }
      } else {
        this.disableHighlight();
        this.hoveredStateId = undefined;
      }
    },
    mouseOutEventHandler() {
      if (this.hoveredStateId !== undefined) {
        this.disableHighlight();
        this.hoveredStateId = undefined;
      }
    },
    siteAndBuildingClickHandler(boundingBox) {
      let selectedFeatures = this.map.queryRenderedFeatures(boundingBox, {
        layers: [
          "symbol_site-outline_ptr",
          "fill_building-outline_ptr",
          "symbol_building-outline_ptr",
          "fill_selected_ptr",
          "symbol_selected_ptr",
          "fill_global-geofence_ptr"
        ]
      });
      let clickedId = selectedFeatures?.[0]?.properties?.fid;
      if (clickedId !== undefined) {
        this.$router
          .push({
            name: "GlobalGeofenceEdit",
            params: { globalGeofenceId: clickedId, contentType: "global-geofences" }
          })
          .catch((e) => console.log(e.message));
        this.$emit("showAddEditPanel");
        return true;
      }
      clickedId = selectedFeatures?.[0]?.properties?.siteInternalIdentifier;
      if (clickedId !== undefined) {
        this.$router.push({ name: "Buildings", params: { siteId: clickedId } }).catch((e) => console.log(e.message));
        return true;
      }
      clickedId = selectedFeatures?.[0]?.id;
      if (clickedId !== undefined) {
        this.$router
          .push({ name: "Levels", params: { buildingId: clickedId, contentType: "levels" } })
          .catch((e) => console.log(e.message));
        return true;
      }
      return false;
    },
    contentClickHandler(boundingBox) {
      let selectedFeatures = this.map.queryRenderedFeatures(boundingBox).filter((feature) => {
        return feature.layer.id.endsWith("ptr");
      });
      if (selectedFeatures.length === 0) {
        return;
      } else {
        const clickedFeature = this.getFocusedFeature(selectedFeatures);
        if (clickedFeature) {
          this.disableHighlight();
          this.$router
            .push({
              params: { featureId: clickedFeature.properties.fid }
            })
            .catch((e) => console.log(e.message));
        }
      }
    },
    poiClickHandler(boundingBox) {
      let selectedFeatures = this.map.queryRenderedFeatures(boundingBox).filter((feature) => {
        return feature.layer.id.endsWith("ptr");
      });
      if (selectedFeatures.length === 0) {
        return;
      } else {
        const clickedFeature = this.getFocusedFeature(selectedFeatures);
        if (clickedFeature && this.isPoi(clickedFeature) && !this.isAddEditPanelActive) {
          this.disableHighlight();
          this.$router
            .push({
              params: { featureId: clickedFeature.properties.fid }
            })
            .catch((e) => console.log(e.message));
        }
      }
    },
    clickEventHandler(e) {
      // CPD-1611. We're adding background loading and disabling interaction while changing map objects.
      if (this.backgroundLoadingRequestCount > 0) {
        return;
      }
      if (
        this.$route.name.includes("Edit") ||
        this.$route.name.includes("Add") ||
        this.$route.name.includes("Level") ||
        this.$route.name.includes("Picker") ||
        this.$route.name.includes("BeaconList") ||
        this.$route.name.includes("WayfindingNetwork") ||
        this.isAddEditPanelActive
      ) {
        return;
      }
      const boundingBox = [
        [e.point.x - 1, e.point.y - 1],
        [e.point.x + 1, e.point.y + 1]
      ];

      if (this.$route.name.includes("PoiList")) {
        this.poiClickHandler(boundingBox);
        return;
      }

      if (this.siteAndBuildingClickHandler(boundingBox)) {
        return;
      }
      this.contentClickHandler(boundingBox);
    },
    isPoi(feature) {
      return this.taxonomy[feature?.properties?.typeCode]?.properties?.class === "poi";
    }
  }
};
</script>

<style lang="scss">
#content-map {
  height: 100vh;

  .maplibregl-ctrl-attrib {
    display: none;
  }

  .maplibregl-canvas-container {
    height: 100vh;
    flex-shrink: 0;
  }

  .maplibregl-ctrl-group {
    display: none;
  }

  .svg {
    position: absolute;
    opacity: 0.5;
    border: 1px dashed var(--v-neutral-base);
    z-index: 999;
    background-color: var(--v-white-base);
  }

  .rotate {
    cursor: url(../assets/cursors/rotate.png) 20 20, default;
  }

  .scaleNWSE {
    cursor: url(../assets/cursors/scale-2.png) 20 20, default;
  }

  .scaleNESW {
    cursor: url(../assets/cursors/scale.png) 20 20, default;
  }

  .grab {
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .grabbing {
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .shadow-marker {
    height: 30px;
    width: 30px;
    border: 5px solid rgb(243, 199, 4);
    border-radius: 50%;
  }

  .node-marker,
  .node-marker-point,
  .beacon-marker {
    height: 16px;
    width: 16px;
    background: var(--v-primary-base);
    border: 2px solid var(--v-white-base);
    border-radius: 50%;
    cursor: pointer;

    &.ghost {
      opacity: 0.4;
    }
  }

  .node-marker-hidden {
    display: none;
  }

  .neighbor-marker-warning {
    filter: hue-rotate(230deg) saturate(1);
  }

  .neighbor-marker-error {
    filter: hue-rotate(230deg) saturate(5);
  }

  .warning-popup {
    .maplibregl-popup-content {
      text-align: left;
      background: var(--v-neutral-darken4);
      color: var(--v-neutral-lighten4);
      border-radius: 8px;
      padding: 8px;
      font-size: #{$font-size-1};
      font-style: normal;
      font-weight: 400;
      line-height: #{$line-height-xs};
      width: auto;

      p {
        margin-bottom: 0px;
      }

      p:first-child {
        margin-bottom: 3px;
      }

      p:only-child {
        margin-bottom: 0px;
      }
    }

    .maplibregl-popup-tip {
      border-top-color: var(--v-neutral-darken4);
    }
  }

  .middlepoint-marker {
    height: 13px;
    width: 13px;
    background: var(--v-warning-base);
    border: 1px solid var(--v-white-base);
    border-radius: 50%;
    cursor: pointer;
  }

  .transition-node {
    cursor: pointer;
    height: 24px;
    width: 24px;
    z-index: 8;

    &.highlighted {
      width: 32px;
      height: 32px;
      z-index: 9;
    }
  }

  .building-entrance-exit {
    z-index: 9;
  }

  .wayfinding-transition-node {
    height: 24px;
    width: 24px;
    z-index: 8;
    cursor: pointer;

    &.selected {
      width: 32px;
      height: 32px;
    }
  }

  .ghost-anchor-marker,
  .anchor-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: #{$font-size-1};
    line-height: 10px;
  }

  .anchor-marker {
    width: 24px;
    height: 24px;
    background-color: var(--v-white-base);
    color: var(--v-neutral-base);
    border: 1px solid var(--v-neutral-base);

    &.primary-color {
      background-color: var(--v-primary-base);
      border: 1px solid var(--v-primary-base);
      color: var(--v-white-base);
    }

    &.dashed-border {
      background-color: var(--v-white-base);
      border: 1px dashed var(--v-primary-base);
      color: var(--v-primary-base);
      cursor: pointer;
    }
  }

  .ghost-anchor-marker {
    width: 10px;
    height: 10px;
    background-color: var(--v-primary-base);
  }
}
</style>
