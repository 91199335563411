<template>
  <div class="content-view">
    <PtrSidebar v-if="!isPreview">
      <router-view
        :key="refreshKey"
        ref="contentPage"
        class="px-4 left-content"
        :class="{ shrunk: isMenuShrunk }"
        @showAddEditPanel="togglePanel(true)"
        @hideAddEditPanel="togglePanel(false)"
      >
        <template #expander>
          <PtrMenuExpander :is-menu-shrunk="isMenuShrunk" @toggleMenu="toggleMenu" />
        </template>
        <template #header="{ goBack = true, shouldHideBackBtn = false, backButtonId = 'content-back-button' }">
          <v-row
            no-gutters
            class="left-content__header flex-nowrap mt-3 align-center"
            :class="{ 'ml-3': shouldHideBackBtn }"
          >
            <v-col cols="auto" class="mr-4 ml-2" :style="{ display: shouldHideBackBtn ? 'none' : 'block' }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-btn
                      :id="backButtonId"
                      color="white"
                      class="back-button v-btn--square"
                      :disabled="isGoBackDisabled"
                      small
                      icon
                      depressed
                      plain
                      @click="goBack"
                    >
                      <PtrIcon icon="caret-left" />
                    </v-btn>
                  </span>
                </template>
                <span style="margin-top: -2px">{{ getGoBackTooltip }}</span>
              </v-tooltip>
            </v-col>
            <v-tooltip bottom content-class="breadcrumb-tooltip" :disabled="shouldDisableTooltip">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <PtrBreadcrumb
                    :should-hide-back-button="shouldHideBackBtn"
                    @textUpdated="(text) => updateBreadcrumbTooltip(text)"
                    @isActiveChanged="(val) => onBreadcrumbClick(val)"
                  />
                </span>
              </template>
              <span v-if="!isEditOrAddPage">{{ getLevelSwitcherTooltip }}</span>
            </v-tooltip>

            <v-spacer />
            <v-tooltip bottom content-class="shrink-menu-tooltip">
              <template #activator="{ on, attrs }">
                <v-btn
                  class="v-btn--square align-self-center mr-4 shrink-menu-button"
                  small
                  icon
                  depressed
                  plain
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleMenu"
                >
                  <PtrIcon icon="shrink-menu" />
                </v-btn>
              </template>
              <span>{{ $t("button-tooltips.collapse-menu") }}</span>
            </v-tooltip>
          </v-row>
        </template>
        <template #buttons="{ isValid, save, goBack }">
          <v-card tile class="pa-4 ms-n4">
            <v-row>
              <v-col>
                <v-btn id="cancel" outlined block @click="goBack">{{ $t("cancel") }}</v-btn>
              </v-col>
              <v-col>
                <v-btn id="save" color="primary" depressed block :disabled="!isValid || !isFormDirty" @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </router-view>
    </PtrSidebar>

    <div
      class="right-content"
      :class="{
        'map-edit-border': isMapBorderEnabled
      }"
    >
      <ContentAddEditPanel
        v-if="displayAddEditPanel"
        ref="contentAddEditPanel"
        :is-closing="!isAddEditPanelActive"
        @close="togglePanel(false)"
      ></ContentAddEditPanel>
      <div class="left border"></div>
      <div class="top border"></div>
      <div class="right border"></div>
      <div class="bottom border"></div>
      <div :class="{ 'ai-in-progress': isAIMappingInProgress }"></div>

      <BuildingLevelSelector v-if="shouldShowMapIcons.buildingLevelSelector.shouldShow" />
      <PtrBackgroundLoading v-if="backgroundLoadingRequestCount > 0" />
      <div
        class="map-wrapper"
        :class="{
          'sliding-to-left': isAddEditPanelClosing,
          'sliding-to-right': isAddEditPanelOpening
        }"
      >
        <div v-if="isDragging" id="drop-area" @drop="(event) => onFileDrop(event)"></div>
        <PreviewMap v-if="isPreview"></PreviewMap>
        <ContentMap v-else @showAddEditPanel="togglePanel(true)"></ContentMap>
        <MapUI>
          <template #topLeftTowardsRight>
            <div v-if="shouldShowMapIcons.search.shouldShow">
              <MapSearch />
            </div>
            <div v-if="shouldShowMapIcons.buildingSiteSelector.shouldShow">
              <BuildingSiteSelector></BuildingSiteSelector>
            </div>
          </template>
          <template #topLeftTowardsDown>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="shouldShowMapIcons.polygon.shouldShow"
                  id="map-ui-polygon"
                  color="white"
                  class="v-btn--square"
                  :disabled="!!drawnCoordinates"
                  :class="{ selected: !drawnCoordinates && mapMode === MAP_MODES.DRAW_POLYGON }"
                  v-bind="attrs"
                  v-on="on"
                  @click="setMode(MAP_MODES.DRAW_POLYGON)"
                >
                  <PtrIcon icon="draw-polygon" />
                </v-btn>
              </template>
              <span>{{ $t("button-tooltips.draw-polygon") }}</span>
            </v-tooltip>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="shouldShowMapIcons.point.shouldShow"
                  id="map-ui-point"
                  color="white"
                  class="v-btn--square"
                  :disabled="!!drawnCoordinates"
                  :class="{ selected: !drawnCoordinates && mapMode === MAP_MODES.DRAW_POINT }"
                  v-bind="attrs"
                  v-on="on"
                  @click="setMode(MAP_MODES.DRAW_POINT)"
                >
                  <PtrIcon icon="draw-marker" />
                </v-btn>
              </template>
              <span>{{ $t("button-tooltips.draw-point") }}</span>
            </v-tooltip>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="shouldShowMapIcons.circle.shouldShow"
                  id="map-ui-circle"
                  color="white"
                  class="v-btn--square"
                  :disabled="!!drawnCoordinates"
                  :class="{ selected: !drawnCoordinates && mapMode === MAP_MODES.DRAG_CIRCLE }"
                  v-bind="attrs"
                  v-on="on"
                  @click="setMode(MAP_MODES.DRAG_CIRCLE)"
                >
                  <PtrIcon icon="draw-circle" />
                </v-btn>
              </template>
              <span>{{ $t("button-tooltips.draw-circle") }}</span>
            </v-tooltip>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="shouldShowMapIcons.cut.shouldShow"
                  id="map-ui-cut"
                  color="white"
                  class="v-btn--square cut"
                  :disabled="!drawnCoordinates"
                  :class="{ selected: mapMode === MAP_MODES.CUT_POLYGON }"
                  v-bind="attrs"
                  v-on="on"
                  @click="setMode(MAP_MODES.CUT_POLYGON)"
                >
                  <PtrIcon icon="draw-cut" />
                </v-btn>
              </template>
              <span>{{ $t("button-tooltips.cut") }}</span>
            </v-tooltip>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="shouldShowMapIcons.graph.shouldShow"
                  id="map-ui-graph"
                  color="white"
                  class="v-btn--square"
                  :class="{ selected: mapMode === MAP_MODES.DRAW_GRAPH_MODE }"
                  v-bind="attrs"
                  v-on="on"
                  @click="setMode(MAP_MODES.DRAW_GRAPH_MODE)"
                >
                  <PtrIcon icon="draw-polygon-filled" />
                </v-btn>
              </template>
              <span>{{ $t("button-tooltips.draw-graph") }}</span>
            </v-tooltip>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="shouldShowMapIcons.delete.shouldShow && (drawnCoordinates || isGraphDrawn)"
                  id="map-ui-delete"
                  color="error"
                  class="v-btn--square"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteAll"
                >
                  <PtrIcon icon="draw-bin" />
                </v-btn>
              </template>
              <span>{{ $t("button-tooltips.delete") }}</span>
            </v-tooltip>
          </template>

          <template #bottomLeftTowardsUp></template>

          <template #bottomLeftTowardsRight></template>

          <template #centerTop>
            <v-btn v-if="shouldShowMapIcons.eye.shouldShow" id="map-ui-eye" color="white" class="v-btn--square">
              <PtrIcon icon="eye" editable-color />
            </v-btn>
          </template>

          <template #centerBottom>
            <div
              v-if="!isWhiteLabelEnabled"
              :style="isAddEditPanelActive ? 'margin-right:320px' : ''"
              class="copyright-area"
            >
              <PtrIcon icon="copyright-logo" width="64" />
              <div class="copyright-text">© Pointr {{ new Date().getFullYear() }} and © OpenStreetMap contributors</div>
            </div>
          </template>

          <template #topRightTowardsLeft>
            <StageButton v-if="!shouldShowMapIcons.publish.shouldShow"></StageButton>
            <v-btn
              v-if="shouldShowMapIcons.publish.shouldShow"
              id="preview-cancel-button"
              class="preview-button"
              @click="publishModalHandler({ shouldShowPublishModal: false, shouldShowCancelModal: true })"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              v-if="shouldShowMapIcons.publish.shouldShow"
              id="preview-publish-button"
              class="preview-button"
              color="success"
              depressed
              @click="publishModalHandler({ shouldShowPublishModal: true, shouldShowCancelModal: false })"
            >
              {{ $t("publish") }}
            </v-btn>
          </template>

          <template #bottomRightTowardsUp>
            <LevelSelector v-if="shouldShowMapIcons.levelSelector.shouldShow"></LevelSelector>
            <PathLevelSelector v-if="shouldShowMapIcons.pathLevelSelector.shouldShow"></PathLevelSelector>
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="shouldShowMapIcons.target.shouldShow"
                  id="map-ui-target"
                  :disabled="shouldShowMapIcons.target.isDisabled"
                  color="white"
                  class="v-btn--square"
                  v-bind="attrs"
                  v-on="on"
                  @click="locateMe"
                >
                  <PtrIcon icon="map-center" editable-color />
                </v-btn>
              </template>
              <span>{{ $t("button-tooltips.locate") }}</span>
            </v-tooltip>
            <v-btn v-if="shouldShowMapIcons.help.shouldShow" id="map-ui-help" color="white" class="v-btn--square">
              <PtrIcon icon="help" editable-color />
            </v-btn>
            <v-btn
              v-if="shouldShowMapIcons.zoom.shouldShow"
              id="map-ui-zoom-in"
              color="white"
              class="v-btn--square"
              :disabled="zoom === maxZoom"
              @click="zoomIn"
            >
              <PtrIcon icon="plus" editable-color />
            </v-btn>
            <v-btn
              v-if="shouldShowMapIcons.zoom.shouldShow"
              id="map-ui-zoom-out"
              color="white"
              class="v-btn--square"
              :disabled="zoom === minZoom"
              @click="zoomOut"
            >
              <PtrIcon icon="minus" editable-color />
            </v-btn>
          </template>

          <template #bottomRightTowardsLeft></template>
        </MapUI>
        <GuidancePopup v-if="guidanceMessage"></GuidancePopup>
        <MapSettings></MapSettings>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PtrSidebar from "@/components/shared/PtrSidebar.vue";
import MapUI from "@/components/MapUI.vue";
import ContentMap from "@/components/ContentMap.vue";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import PtrBreadcrumb from "@/components/shared/PtrBreadcrumb.vue";
import PtrMenuExpander from "@/components/shared/PtrMenuExpander.vue";
import MapSearch from "@/components/MapSearch.vue";
import LevelSelector from "@/components/preview/LevelSelector";
import PreviewMap from "@/components/preview/PreviewMap.vue";
import StageButton from "@/components/preview/StageButton.vue";
import BuildingSiteSelector from "@/components/preview/BuildingSiteSelector";
import PtrBackgroundLoading from "@/components/shared/PtrBackgroundLoading.vue";
import ContentAddEditPanel from "@/components/ContentAddEditPanel.vue";
import BuildingLevelSelector from "@/components/mapDesigner/BuildingLevelSelector.vue";
import PathLevelSelector from "@/components/shared/PathLevelSelector.vue";
import MapSettings from "@/components/MapSettings/MapSettings.vue";
import MapModeConstants from "@/constants/mapModeConstants";
import MapHelpers from "@/helpers/MapHelpers";
import PointMode from "@/helpers/drawModes/PointMode";
import GraphMode from "@/helpers/drawModes/GraphMode";
import GuidancePopup from "@/components/shared/GuidancePopup.vue";

export default {
  name: "ContentView",
  components: {
    PathLevelSelector,
    PtrSidebar,
    MapUI,
    ContentMap,
    PtrIcon,
    PtrBreadcrumb,
    PtrMenuExpander,
    MapSearch,
    LevelSelector,
    PreviewMap,
    StageButton,
    BuildingSiteSelector,
    PtrBackgroundLoading,
    ContentAddEditPanel,
    GuidancePopup,
    BuildingLevelSelector,
    MapSettings
  },

  data: () => ({
    isPreview: false,
    isMenuShrunk: false,
    displayAddEditPanel: false,
    breadcrumbTooltipText: "",
    shouldDisableTooltip: false,
    isWhiteLabelEnabled: false
  }),

  computed: {
    ...mapState("MAP", [
      "map",
      "mapMode",
      "shouldShowMapIcons",
      "zoom",
      "maxZoom",
      "minZoom",
      "drawnCoordinates",
      "isGraphDrawn",
      "isAddEditPanelActive",
      "isMapBorderEnabled",
      "isAIMappingInProgress",
      "isAddEditPanelOpening",
      "isAddEditPanelClosing",
      "guidanceMessage"
    ]),
    ...mapGetters("MAP", ["currentSiteObject", "currentBuildingObject"]),
    ...mapState("CONTENT", ["refreshKey", "isFormDirty", "isDragging"]),
    loadingRequestCount() {
      return this.$store.state.loadingRequestCount || 0;
    },
    previewMapZoom() {
      return this.$store.state.PREVIEW_MAP.zoom;
    },
    MAP_MODES() {
      return MapModeConstants.MAP_MODES;
    },
    isEditOrAddPage() {
      const path = window.location.pathname;
      return path.includes("edit") || path.includes("add");
    },
    getLevelSwitcherTooltip() {
      if (this.$route.name.includes("Edit") && !this.$route.name.includes("Client")) {
        return this.$t("button-tooltips.go-back-disabled-edit");
      } else if (this.$route.name.includes("Add")) {
        return this.$t("button-tooltips.go-back-disabled-add");
      } else if (this.$route.params.featureId) {
        if (this.$route.params.featureId === "add") {
          return this.$t("button-tooltips.go-back-disabled-add");
        } else {
          return this.$t("button-tooltips.go-back-disabled-edit");
        }
      } else {
        return this.breadcrumbTooltipText;
      }
    },
    getGoBackTooltip() {
      if (this.$route.name.includes("Edit") && !this.$route.name.includes("Client")) {
        return this.$t("button-tooltips.go-back-disabled-edit");
      } else if (this.$route.name.includes("Add")) {
        return this.$t("button-tooltips.go-back-disabled-add");
      } else if (this.$route.params.featureId) {
        if (this.$route.params.featureId === "add") {
          return this.$t("button-tooltips.go-back-disabled-add");
        } else {
          return this.$t("button-tooltips.go-back-disabled-edit");
        }
      } else {
        return this.$t("button-tooltips.go-back");
      }
    },
    backgroundLoadingRequestCount() {
      return this.$store.state.backgroundLoadingRequestCount || 0;
    },
    isGoBackDisabled() {
      return this.isAddEditPanelActive || this.backgroundLoadingRequestCount > 0;
    }
  },

  watch: {
    $route() {
      this.isPreview = this.$route.name === "Preview";
    },
    isAddEditPanelActive() {
      if (this.isAddEditPanelActive) {
        this.displayAddEditPanel = true;
      } else {
        setTimeout(() => {
          this.displayAddEditPanel = false;
        }, 500);
      }
      this.animateMap({
        padding: this.isAddEditPanelActive ? 280 : 0,
        transitionDuration: 400,
        shouldCheckIfResizeRequired: !this.isAddEditPanelActive
      });
    }
  },

  created() {
    this.isPreview = this.$route.name === "Preview";
    this.isWhiteLabelEnabled = process.env.VUE_APP_WHITE_LABEL.toLowerCase() === "true";
  },

  mounted() {
    const gl = document.createElement("canvas").getContext("webgl2");
    if (!gl) {
      if (typeof WebGL2RenderingContext !== "undefined") {
        alert(
          "Pointr needs WebGL2 to work, your browser appears to support WebGL2 but it might be disabled. Try updating your OS and/or video card drivers."
        );
      } else {
        alert("Pointr needs WebGL2 to work, your browser has no WebGL2 support.");
      }
    }
  },

  methods: {
    setMode(mode) {
      this.$store.commit("MAP/MAP_MODE", this.mapMode === mode ? MapModeConstants.EMPTY_MODE : mode);
    },
    deleteAll() {
      PointMode.clearPoints();
      GraphMode.clear({ shouldFireDeleteEvent: true });
      this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
      this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
      this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      // Currently used to reset graph form
      if (this.$refs.contentPage.clearForm) {
        this.$refs.contentPage.clearForm();
        this.$store.commit("CONTENT/SELECTED_CONTENT", undefined);
      }
    },
    zoomIn() {
      if (this.isPreview) {
        this.$store.commit("PREVIEW_MAP/ZOOM", this.previewMapZoom + 1);
      } else {
        this.$store.commit("MAP/ZOOM", this.zoom + 1);
      }
    },
    zoomOut() {
      if (this.isPreview) {
        this.$store.commit("PREVIEW_MAP/ZOOM", this.previewMapZoom - 1);
      } else {
        this.$store.commit("MAP/ZOOM", this.zoom - 1);
      }
    },
    locateMe() {
      const coordinates =
        this.currentBuildingObject?.geometry?.coordinates[0] || this.currentSiteObject?.geometry?.coordinates[0];
      MapHelpers.fitBounds(coordinates);
    },
    publishModalHandler({ shouldShowPublishModal, shouldShowCancelModal }) {
      this.$store.commit("PREVIEW_MAP/SHOULD_SHOW_PUBLISH_MODAL", shouldShowPublishModal);
      this.$store.commit("PREVIEW_MAP/SHOULD_SHOW_CANCEL_MODAL", shouldShowCancelModal);
    },
    togglePanel(shouldShow) {
      if (!shouldShow && this.isAddEditPanelActive) {
        MapHelpers.panMapBy(-160, 0);
      } else if (shouldShow && !this.isAddEditPanelActive) {
        MapHelpers.panMapBy(160, 0);
      }
      this.$store.commit("MAP/IS_ADD_EDIT_PANEL_ACTIVE", shouldShow);
      this.$store.commit("MAP/IS_ADD_EDIT_PANEL_OPENING", shouldShow);
      this.$store.commit("MAP/IS_ADD_EDIT_PANEL_CLOSING", !shouldShow);
    },
    toggleMenu() {
      this.isMenuShrunk = !this.isMenuShrunk;
      this.animateMap({
        padding: this.isMenuShrunk ? -320 : 0,
        transitionDuration: 500,
        shouldCheckIfResizeRequired: this.isMenuShrunk
      });
    },
    animateMap(options) {
      const { padding, transitionDuration, shouldCheckIfResizeRequired } = options;
      this.map.easeTo({
        padding: padding,
        duration: transitionDuration // In ms. This matches the CSS transition duration property.
      });
      // Resize map if necessary
      if (shouldCheckIfResizeRequired) {
        const checkInterval = 10;
        let interval = setInterval(() => {
          const canvasContainerWidth = document.getElementsByClassName("maplibregl-canvas-container")[0].offsetWidth;
          const canvasElWidth = document.getElementsByClassName("maplibregl-canvas")[0].offsetWidth;

          if (canvasContainerWidth > canvasElWidth) {
            this.map.resize();
          }
        }, checkInterval);

        setTimeout(() => {
          clearInterval(interval);
        }, transitionDuration + 100);
      }
    },
    onFileDrop(event) {
      event.preventDefault();
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        this.$store.commit("CONTENT/IS_DRAGGING", false);
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
        return;
      }
      this.$refs.contentAddEditPanel.onDropFile(files[0]);
    },
    updateBreadcrumbTooltip(text) {
      this.breadcrumbTooltipText = text;
    },
    onBreadcrumbClick(val) {
      this.shouldDisableTooltip = val;
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/variables.scss";
// Adds opacity to list elements at clients, sites, buildings, levels pages but not to nested list of mapDesigner page
$inner-left-content-width: $left-content-width - 2 * 16;

.content-list.v-list {
  .v-list-item {
    border: 1px solid transparent !important;
    border-radius: 8px !important;

    &::before {
      border-radius: 8px;
    }

    &__action:last-of-type:not(:only-child) {
      margin-left: auto;
      min-width: 0 !important;
      align-items: center;

      button {
        margin-left: 8px;
      }
    }

    &__title {
      font-size: #{$font-size-3};
      line-height: #{$line-height-15};
      color: var(--v-neutral-darken3);
    }

    &__subtitle {
      font-size: #{$font-size-1} !important;
      line-height: #{$line-height-xs};
      color: var(--v-neutral-base) !important;
    }
  }

  &:not(.nested-groups) {
    .v-list-item {
      &::before {
        opacity: 0 !important;
      }
    }
  }

  &:not(.v-list--flat) {
    .v-list-item {
      &:hover {
        border: 1px solid var(--v-primary-lighten3) !important;
      }

      &:not(.content):active {
        border: 1px solid var(--v-primary-base) !important;

        .v-list-item__title {
          color: var(--v-primary-base) !important;
        }
      }
    }
  }
}

.left-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  box-shadow: 0px 4px 8px rgba(23, 25, 28, 0.16);
  width: #{$left-content-width};
  transition: width 0.5s linear;
  position: relative;
  overflow: hidden;

  .topic {
    font-size: #{$font-size-6};
    font-weight: #{$font-weight-medium};
    line-height: #{$line-height-10};
    color: var(--v-neutral-darken5);

    &.larger-font {
      font-size: #{$font-size-8};
      line-height: 32px;
      font-weight: 400;
    }

    .thinner-font {
      font-weight: 400;
    }
  }

  .sub-topic {
    font-weight: 400;
    color: var(--v-neutral-darken1);
    min-width: $inner-left-content-width;
    flex-shrink: 0;
  }

  .count-info {
    font-size: #{$font-size-2};
    color: var(--v-neutral-base);
    line-height: #{$line-height-15};
    min-width: $inner-left-content-width;
    flex-shrink: 0;
  }

  .header {
    width: $inner-left-content-width;
    flex-shrink: 0;
  }

  > * {
    &:not(.left-menu-expander) {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }

  &.shrunk {
    padding: 0;
    width: #{$menu-expander-width};
    transition: width 0.5s linear;

    > * {
      &:not(.left-menu-expander):not(.form-view) {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        pointer-events: none;
      }
    }

    .form-view {
      > * {
        &:not(.left-menu-expander) {
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
          pointer-events: none;
        }
      }
    }
  }

  .left-content__header,
  .content-list,
  .filter,
  .topic {
    width: #{$inner-left-content-width};
  }

  .form-view {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1 !important;
  }

  .left-content__header {
    min-height: 40px !important;
    height: 40px;
    display: flex;

    > :nth-child(3) {
      flex-grow: 1;
    }
  }

  > .v-card {
    &.pa-4 {
      &.ms-n4 {
        min-width: calc(100% + 32px);
      }
    }
  }
}

.content-view {
  display: flex;
  height: 100%;

  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 40px;
    min-height: 40px;
    height: auto;
  }

  div.border {
    position: absolute;
    display: none;
    z-index: 2;
    background-color: var(--v-primary-base);

    &.top {
      top: 0;
      right: 0;
      width: calc(100% - #{$content-add-edit-panel-width});
      height: 8px;
    }

    &.bottom {
      bottom: 0;
      right: 0;
      width: calc(100% - #{$content-add-edit-panel-width});
      height: 8px;
    }

    &.left {
      top: 0;
      left: #{$content-add-edit-panel-width};
      width: 10px;
      height: 100%;
    }

    &.right {
      bottom: 0;
      right: 0;
      width: 10px;
      height: 100%;
    }
  }

  .map-edit-border {
    div.border {
      display: block;
    }
  }

  .right-content {
    width: 100%;
    position: relative;
    min-width: 270px;
    display: flex;

    .preview-button {
      width: 100px;
    }

    .map-wrapper {
      position: relative;
      width: 100%;
      flex-shrink: 0;
    }
  }
}

#drop-area {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}

.ai-in-progress {
  position: absolute !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(52, 109, 241, 0.5);
  z-index: 1 !important;
}

.ai-in-progress::before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 10px;
  background: var(--v-primary-lighten5);
  box-shadow: 0px 0px 26px rgba(255, 255, 255, 0.95);
  animation: x 3s linear infinite;
  animation-direction: alternate;
}
.copyright-area {
  display: inline-flex;
  margin-bottom: -8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  .copyright-text {
    color: var(--v-neutral-darken1);
    text-align: right;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
    font-family: Arial, sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 10px; /* 100% */
    letter-spacing: -0.408px;
  }
}

@keyframes x {
  to {
    transform: translateX(-100%);
    left: 100%;
  }
}
</style>
